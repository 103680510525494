// Quiz.js
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import {
  collection,
  doc,
  setDoc,
  getDocs,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import { UserAuth } from "../../Context/AuthContext";
import "./Quiz.css";

function Quiz() {
  const quizTestQuestions = [];
  const { user } = UserAuth();
  const [selectedOptions, setSelectedOptions] = useState({});
  const [score, setScore] = useState(0);
  const [showResults, setShowResults] = useState(-1);
  const [quizList, setQuizList] = useState([]);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [selectedQuizQuestions, setSelectedQuizQuestions] = useState([]);
  const [quizTitle, setQuizTitle] = useState(null);
  const [videoList, setVideoList] = useState([]);
  const [quizVideos, setQuizVideos] = useState([]);
  const [docCount, setDocCount] = useState(0);
  const [vidCount, setVidCount] = useState(0);
  const [files, setFiles] = useState([]);
  const [showIframe, setShowIframe] = useState(false);
  const [quizDocs, setQuizDocs] = useState([]);
  let quizDescription = "";

  let usersAnswers = [];
  const passingGrade = selectedQuizQuestions.length * 0.8;
  let finalGrade = (score / selectedQuizQuestions.length) * 100;
  let dbGrade = 0;
  let status = "";
  let currentTime = new Date().toLocaleTimeString();
  let currentDate = new Date().toLocaleString();
  let quizVideosFiltered = [];

  useEffect(() => {
    const quizRef = collection(db, "/quizzes");
    getDocs(quizRef)
      .then((snapshot) => {
        const quizChoices = [];
        snapshot.docs.forEach((doc) => {
          quizChoices.push({ ...doc.data(), id: doc.id });
        });
        setQuizList(quizChoices);
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [user.uid]);

  useEffect(() => {
    const videoRef = collection(db, "/videos");
    getDocs(videoRef)
      .then((snapshot) => {
        const videos = [];
        snapshot.docs.forEach((doc) => {
          videos.push({ ...doc.data(), id: doc.id });
        });
        setVideoList(videos);
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [user.uid]);
  useEffect(() => {
    loadQuizDocuments();
  }, []);
  const loadQuizDocuments = async () => {
    try {
      const storageRef = ref(getStorage(), "quiz_documents/");
      const result = await listAll(storageRef);
      const documents = await Promise.all(
        result.items.map(async (itemRef) => {
          const downloadURL = await getDownloadURL(itemRef);
          const documentData = { name: itemRef.name, url: downloadURL };
          return documentData;
        })
      );
      setFiles(documents);
    } catch (error) {
      console.error(error);
    }
  };
  const handleQuizChoice = async (quiz) => {
    setSelectedQuiz(quiz);
    setQuizTitle(quiz.title);
    const quizID = quiz.id;
    const quizRequiredDocs = quiz.required_documents;
    quizDescription = quiz.description;
    quizVideosFiltered = videoList.filter((video) =>
      video.quiz_id.includes(quizID)
    );
    setQuizDocs(
      files.filter(function (file) {
        return quizRequiredDocs.indexOf(file.name) >= 0;
      })
    );
    setQuizVideos(quizVideosFiltered);

    setShowResults(3);
  };
  const handleQuizRender = async () => {
    const quizID = selectedQuiz.id;

    const quizQuestionsRef = collection(
      db,
      "/quizzes/" + quizID + "/questionList"
    );

    try {
      await getDocs(quizQuestionsRef).then((snapshot) => {
        const quizQuestionsCollection = [];
        snapshot.docs.forEach((doc) => {
          quizQuestionsCollection.push({ ...doc.data(), id: doc.id });
        });
        for (let i = 0; i < quizQuestionsCollection.length; i++) {
          const answer = quizQuestionsCollection[i].answer;
          const option1 = quizQuestionsCollection[i].option1;
          const option2 = quizQuestionsCollection[i].option2;
          const option3 = quizQuestionsCollection[i].option3;
          const option4 = quizQuestionsCollection[i].option4;
          const questionText = quizQuestionsCollection[i].question;
          const docID = quizQuestionsCollection[i].id;
          const question = {
            question: questionText,
            options: [option1, option2, option3, option4],
            correctAnswer: answer,
            questionID: docID,
          };
          quizTestQuestions.push(question);
        }
        //console.log(quizTestQuestions);
        //console.log(quizQuestionsCollection);
        setSelectedQuizQuestions(quizTestQuestions);
        //console.log(selectedQuizQuestions);
        setShowResults(0);
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleCloseQuizClick = () => {
    setSelectedQuiz(null);
    setShowResults(-1);
    setSelectedOptions({});
    setScore(0);
    usersAnswers = [];
    dbGrade = 0;
  };

  function handleOptionChange(questionIndex, e) {
    setSelectedOptions({
      ...selectedOptions,
      [questionIndex]: e.target.value,
    });
  }

  function handleResetClick() {
    setSelectedOptions({});
    setScore(0);
    setShowResults(0);
    usersAnswers = [];
    dbGrade = 0;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    selectedQuizQuestions.forEach((question, index) => {
      usersAnswers.push(selectedOptions[index]);

      if (selectedOptions[index] === question.correctAnswer) {
        dbGrade += 1;
        setScore((prevScore) => prevScore + 1);
      }
    });
    for (let i = 0; i < usersAnswers.length; i++) {
      if (usersAnswers[i] === undefined) {
        setShowResults(2);
        return false;
      }
    }
    if (dbGrade < passingGrade) {
      status = "FAIL";
    } else {
      status = "PASS";
    }
    setShowResults(1);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    const dbGradePercentage = (dbGrade / selectedQuizQuestions.length) * 100;
    const userRef = doc(db, "users/" + user.uid);

    const docRef = await setDoc(
      doc(userRef, "quiz_submissions", selectedQuiz.id + "_" + currentTime),
      {
        array: usersAnswers,
        score: dbGrade,
        score_percent: dbGradePercentage,
        status: status,
        email: user.email,
        timestamp: currentDate,
        userID: user.uid,
        quizID: selectedQuiz.id,
        quizTitle: quizTitle,
      }
    )
      .then(() => {
        alert("Response Received..");

        ///update passed users array in quiz location
        //WORK IN PROGRESS
      })
      .catch((error) => {
        console.error(error);
      });

    ///update users_taken_quiz array in quiz location
    const userTakenRef = doc(db, "quizzes", selectedQuiz.id);
    const DocRef = await updateDoc(userTakenRef, {
      users_taken_quiz: arrayUnion(user.email),
    })
      .then(() => {
        //alert("Response Received..");
      })
      .catch((error) => {
        console.error(error);
      });
    if (status === "PASS") {
      const userPassedRef = doc(db, "quizzes", selectedQuiz.id);
      const docPassedRef = await updateDoc(userPassedRef, {
        users_passed_quiz: arrayUnion(user.email),
      })
        .then(() => {
          //console.log("Document has been added successfully");
          // alert("Response Received..");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  //const activeVideos = videoList.filter((video) => video.isActive);

  //console.log(quizDocs);
  function handleNextDoc() {
    if (docCount < selectedQuiz.required_documents.length - 1) {
      setDocCount(docCount + 1);
    }
  }
  function handleBackDoc() {
    if (docCount > 0) {
      setDocCount(docCount - 1);
    }
  }
  function handleShowVid() {
    setShowResults(4);
  }
  function handleShowDoc() {
    setShowResults(3);
  }
  function handleNextVid() {
    if (vidCount < quizVideos.length - 1) {
      setVidCount(vidCount + 1);
      //console.log(vidCount);
    }
  }
  function handleBackVid() {
    if (vidCount > 0) {
      setVidCount(vidCount - 1);
    }
  }
  if (showResults === 0) {
    return (
      <div>
        {/* If showResults is false, render the form */}

        <form className="quiz-container" onSubmit={handleFormSubmit}>
          <div className="quiz-title">
            <h1>{quizTitle}</h1>
          </div>
          {/* Map through the questions and render a question and its options */}
          {selectedQuizQuestions.map((question, index) => (
            <div className="question-container" key={question.question}>
              <p className="question-text">
                {index + 1}. {question.question}
              </p>
              {question.options.map((option) => (
                <label className="multiple-choice-label" key={option}>
                  <input
                    className="multiple-choice-input"
                    type="radio"
                    value={option}
                    checked={selectedOptions[index] === option}
                    onChange={handleOptionChange.bind(null, index)}
                  />
                  {option}
                </label>
              ))}
            </div>
          ))}
          {/* <button className="submit-button-quiz" type="submit">
            Submit
          </button> */}
          <div className="d-grid col-5 mx-auto">
            <button className="btn btn-primary" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  } else if (showResults === 1) {
    return (
      <div className="quiz-container">
        <div className="quiz-title">
          <h1>{quizTitle}</h1>
        </div>
        {/* If the score is 4 or higher, render a "pass" message. Otherwise, render a "fail" message. */}
        {score >= passingGrade ? (
          <div className="score-container" style={{ color: "green" }}>
            <p>You passed! Your score is {finalGrade}%.</p>
          </div>
        ) : (
          <div className="score-container" style={{ color: "red" }}>
            <p>You failed. Your score is {finalGrade}%.</p>
          </div>
        )}
        {/* Map through the questions and render a question and its options with the correct answer highlighted in green and incorrect answers highlighted in red */}
        {selectedQuizQuestions.map((question, index) => (
          <div className="question-container" key={question.question}>
            <p className="question-text">
              {index + 1}. {question.question}
            </p>
            {question.options.map((option) => (
              <label
                className="multiple-choice-label"
                key={option}
                style={{
                  color:
                    option === selectedOptions[index]
                      ? option === question.correctAnswer
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                <input
                  className="multiple-choice-input"
                  type="radio"
                  value={option}
                  checked={option === selectedOptions[index]}
                  disabled
                />
                {option}
              </label>
            ))}
          </div>
        ))}
        {/* <button className="submit-button-quiz" onClick={handleResetClick}>
          Reset
        </button> */}
        <div className="d-grid col-5 mx-auto">
          <button
            className="btn btn-outline-primary"
            type="reset"
            onClick={handleResetClick}
          >
            Reset
          </button>
        </div>
        <br />
        <button className="back-button-quiz" onClick={handleCloseQuizClick}>
          Back
        </button>
      </div>
    );
  } else if (showResults === 2) {
    return (
      <div className="quiz-container">
        <div className="quiz-title">
          <h1>{quizTitle}</h1>
        </div>

        <div className="score-container" style={{ color: "red" }}>
          <p>
            Please select an answer for all questions below. Please reset quiz
            before continuing.
          </p>
        </div>

        {/* Map through the questions and render a question and its options with the correct answer highlighted in green and incorrect answers highlighted in red */}
        {selectedQuizQuestions.map((question, index) => (
          <div className="question-container" key={question.question}>
            <p className="question-text">
              {index + 1}. {question.question}
            </p>
            {question.options.map((option) => (
              <label
                className="multiple-choice-label"
                key={option}
                style={{
                  color:
                    option === selectedOptions[index]
                      ? option === question.correctAnswer
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                <input
                  className="multiple-choice-input"
                  type="radio"
                  value={option}
                  checked={option === selectedOptions[index]}
                  disabled
                />
                {option}
              </label>
            ))}
          </div>
        ))}
        {/* <button className="submit-button-quiz" onClick={handleResetClick}>
          Reset
        </button> */}
        <div className="d-grid col-5 mx-auto">
          <button
            className="btn btn-outline-primary"
            type="reset"
            onClick={handleResetClick}
          >
            Reset
          </button>
        </div>
        <br />
        <button className="back-button-quiz" onClick={handleCloseQuizClick}>
          Back
        </button>
      </div>
    );
  } else if (showResults === -1) {
    const activeQuizzes = quizList.filter(
      (quiz) =>
        quiz.isActive && (quiz.eligible_users ?? []).includes(user.email)
    );
    return (
      <main className="container min-vh-100">
        <h1 className="my-3">Quizzes</h1>
        {/* <ul className="unordered-list">
          {activeQuizzes.map((quiz) => {
            const isUserPassed = quiz.users_passed_quiz?.includes(user.email);
            const statusSymbol = isUserPassed ? "✓" : ":incomplete";
            return (
              <li
                key={quiz.id}
                className="quiz-Option"
                onClick={() => handleQuizChoice(quiz)}
              >
                {quiz.title} {statusSymbol}
              </li>
            );
          })}
        </ul> */}
        {/* -----New design----- */}
        <div className="table-responsive">
          <table className="table table-borderless caption-top">
            <caption className="table-title">Quizzes</caption>
            <thead className="table-head">
              <tr>
                <th scope="col">Activity</th>
                {/* <th scope="col">Start Date</th>
                <th scope="col">Finish date</th> */}
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {activeQuizzes.map((quiz) => {
                const isUserPassed = quiz.users_passed_quiz?.includes(
                  user.email
                );
                return (
                  <tr className="align-middle" key={quiz.id}>
                    <td>{quiz.title}</td>
                    {/* <td></td>
                  <td></td> */}
                    <td>
                      {isUserPassed ? (
                        <button className="btn-complete">Completed</button>
                      ) : (
                        <button className="btn-pending">Pending</button>
                      )}
                    </td>
                    <td className="action align-middle">
                      <span
                        className="pointer"
                        onClick={() => handleQuizChoice(quiz)}
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 21 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20.2815 0H3.7815C2.78881 0.000303738 1.83615 0.391403 1.12959 1.08869C0.423041 1.78598 0.019404 2.7334 0.006 3.726L0 3.7185V20.2185C0.000198841 21.2214 0.39867 22.1831 1.1078 22.8922C1.81692 23.6013 2.77864 23.9998 3.7815 24H16.5315C17.3595 24 18 23.328 18 22.5V6.75C18 6.336 17.6955 6 17.2815 6H3.7815C2.523 6 1.5 5.0385 1.5 3.7815C1.5006 3.17659 1.74116 2.59663 2.16889 2.16889C2.59663 1.74116 3.17659 1.5006 3.7815 1.5H19.5V18.75C19.5 19.164 19.8675 19.5 20.2815 19.5C20.6955 19.5 21 19.164 21 18.75V0.75C21 0.336 20.6955 0 20.2815 0ZM3.7815 7.5H16.5015L16.5315 22.5H3.7815C3.17659 22.4994 2.59663 22.2588 2.16889 21.8311C1.74116 21.4034 1.5006 20.8234 1.5 20.2185V6.7665C2.13 7.2285 2.9175 7.5 3.7815 7.5Z"
                            fill="#0FC9DF"
                          />
                        </svg>{" "}
                        Take Quiz &nbsp; &nbsp; &nbsp;
                      </span>
                      {/* {isUserPassed ? (
                        <>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 24 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.5988 18.564H7.18652"
                              stroke="#0FC9DF"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M16.5988 13.1074H7.18652"
                              stroke="#0FC9DF"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10.7781 7.66211H7.18652"
                              stroke="#0FC9DF"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16.8496 1C16.8496 1 6.84161 1.00521 6.82596 1.00521C3.22792 1.02738 1 3.39479 1 7.00587V18.9941C1 22.6235 3.24487 25 6.8742 25C6.8742 25 16.8809 24.9961 16.8979 24.9961C20.4959 24.9739 22.7251 22.6052 22.7251 18.9941V7.00587C22.7251 3.37653 20.479 1 16.8496 1Z"
                              stroke="#0FC9DF"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>{" "}
                          View Result
                        </>
                      ) : (
                        <>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 21 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.76888 24C2.29943 24 1.82998 23.8781 1.3998 23.6324C0.97254 23.3927 0.617289 23.0426 0.371091 22.6185C0.124892 22.1944 -0.00323696 21.7119 6.21364e-05 21.2214V2.77876C6.21364e-05 1.76809 0.523168 0.866838 1.3998 0.36774C2.27548 -0.132317 3.31594 -0.121759 4.183 0.395574L19.6482 9.61736C20.4941 10.1222 21 11.0129 21 12.0005C21 12.9882 20.4941 13.8789 19.6482 14.3837L4.18395 23.6055C3.75643 23.8625 3.26746 23.9988 2.76888 24ZM2.77272 1.92262C2.62325 1.92394 2.47655 1.96324 2.34638 2.03684C2.2144 2.11006 2.10466 2.21767 2.02876 2.34828C1.95286 2.4789 1.91364 2.62764 1.91524 2.77876V21.2223C1.91524 21.6706 2.21608 21.8904 2.34638 21.9643C2.47677 22.0405 2.62534 22.0799 2.7763 22.0784C2.92727 22.0769 3.07501 22.0345 3.20385 21.9556L18.6681 12.7338C19.0436 12.5102 19.0848 12.1464 19.0848 12.0005C19.0848 11.8547 19.0446 11.4909 18.6681 11.2673L3.20289 2.04547C3.07359 1.96547 2.92469 1.92295 2.77272 1.92262Z"
                              fill="#0FC9DF"
                            />
                          </svg>{" "}
                          Complete Quiz
                        </> 
                      )}*/}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </main>
    );
  } else if (
    showResults === 3 &&
    selectedQuiz.required_documents.length === 0 &&
    quizVideos.length === 0
  ) {
    handleQuizRender(selectedQuiz);
  } else if (
    showResults === 3 &&
    selectedQuiz.required_documents.length === 1 &&
    quizVideos.length === 0
  ) {
    return (
      <div className="reg-page">
        <div className="doc-container">
          <h3>Study Material</h3>

          <iframe
            src={quizDocs[docCount].url}
            title="required-document"
            width="95%"
            type="application/pdf"
            height="600px"
          />
          <br />
          <button id="next-doc" onClick={() => handleQuizRender(selectedQuiz)}>
            Finished
          </button>
        </div>
      </div>
    );
  } else if (
    showResults === 3 &&
    selectedQuiz.required_documents.length === 0 &&
    quizVideos.length > 0
  ) {
    handleShowVid();
  } else if (
    showResults === 3 &&
    selectedQuiz.required_documents.length === 1 &&
    quizVideos.length > 0
  ) {
    return (
      <div className="reg-page">
        <div className="doc-container">
          <h3>Study Material</h3>

          <iframe
            src={quizDocs[docCount].url}
            title="required-document"
            width="95%"
            type="application/pdf"
            height="600px"
          />
          <br />
          <button id="next-doc" onClick={handleShowVid}>
            Next
          </button>
        </div>
      </div>
    );
  } else if (
    showResults === 3 &&
    docCount === 0 &&
    selectedQuiz.required_documents.length - 1 !== 0
  ) {
    return (
      <div className="reg-page">
        <div className="doc-container">
          <h3>Study Material</h3>

          <iframe
            src={quizDocs[docCount].url}
            title="required-document"
            width="95%"
            type="application/pdf"
            height="600px"
          />
          <br />
          <button id="next-doc" onClick={handleNextDoc}>
            Next
          </button>
        </div>
      </div>
    );
  } else if (
    showResults === 3 &&
    docCount < selectedQuiz.required_documents.length - 1
  ) {
    return (
      <div className="reg-page">
        <div className="doc-container">
          <h3>Study Material</h3>

          <iframe
            src={quizDocs[docCount].url}
            title="required-document"
            width="95%"
            type="application/pdf"
            height="600px"
          />
          <br />
          <button id="back-vid" onClick={handleBackDoc}>
            Back
          </button>

          <button id="next-doc" onClick={handleNextDoc}>
            Next
          </button>
        </div>
      </div>
    );
  } else if (
    showResults === 3 &&
    docCount === selectedQuiz.required_documents.length - 1 &&
    selectedQuiz.required_documents.length - 1 !== 0
  ) {
    return (
      <div className="reg-page">
        <div className="doc-container">
          <h3>Study Material</h3>

          <iframe
            src={quizDocs[docCount].url}
            title="required-document"
            width="95%"
            type="application/pdf"
            height="600px"
          />
          <br />
          <button id="back-vid" onClick={handleBackDoc}>
            Back
          </button>
          <button id="show-vid" onClick={handleShowVid}>
            Next
          </button>
        </div>
      </div>
    );
  } else if (showResults === 4 && quizVideos.length - 1 === 0) {
    return (
      <div className="reg-page">
        <div className="doc-container">
          <h3>Study Material Video</h3>

          <iframe
            title="example-video"
            className="player"
            src={`https://www.youtube.com/embed/${quizVideos[vidCount].youtube_id}`}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            width="500px"
            height="300px"
          />
          <br />
          <button id="show-doc" onClick={handleShowDoc}>
            Back
          </button>
          <button id="finished" onClick={() => handleQuizRender(selectedQuiz)}>
            Finished
          </button>
        </div>
      </div>
    );
  } else if (showResults === 4 && vidCount === 0) {
    return (
      <div className="reg-page">
        <div className="doc-container">
          <h3>Study Material Video</h3>

          <iframe
            title="example-video"
            className="player"
            src={`https://www.youtube.com/embed/${quizVideos[vidCount].youtube_id}`}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            width="500px"
            height="300px"
          />
          <br />
          <button id="show-doc" onClick={handleShowDoc}>
            Back
          </button>
          <button id="next-vid" onClick={handleNextVid}>
            Next
          </button>
        </div>
      </div>
    );
  } else if (showResults === 4 && vidCount < quizVideos.length - 1) {
    return (
      <div className="reg-page">
        <div className="doc-container">
          <h3>Study Material Video</h3>

          <iframe
            title="example-video"
            className="player"
            src={`https://www.youtube.com/embed/${quizVideos[vidCount].youtube_id}`}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            width="500px"
            height="300px"
          />
          <br />
          <button id="back-vid" onClick={handleBackVid}>
            Back
          </button>
          <button id="next-vid" onClick={handleNextVid}>
            Next
          </button>
        </div>
      </div>
    );
  } else if (showResults === 4 && vidCount === quizVideos.length - 1) {
    return (
      <div className="reg-page">
        <div className="doc-container">
          <h3>Study Material Video</h3>

          <iframe
            title="example-video"
            className="player"
            src={`https://www.youtube.com/embed/${quizVideos[vidCount].youtube_id}`}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            width="500px"
            height="300px"
          />
          <br />
          <button id="back-vid" onClick={handleBackVid}>
            Back
          </button>
          <button id="finished" onClick={() => handleQuizRender(selectedQuiz)}>
            Finished
          </button>
        </div>
      </div>
    );
  }
}
export default Quiz;
