import React, { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../Context/AuthContext";
import LoginPage from "../../Assets/Login Page.png";

function Login() {
  const { signIn, microsoftLogin } = UserAuth();
  const [error, setError] = useState("");
  const Navigate = useNavigate();

  const handleMicrosoftSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await microsoftLogin();
      Navigate("/home");
    } catch (e) {
      setError(e.message);
      console.error(error);
    }
  };

  return (
    <div className="row">
      <div className="col-md-6 img d-flex align-items-center justify-content-center text-white">
        <div className="text-center">
          <h3>
            <b>Welcome To Cyber Security Training</b>
          </h3>
          <h4 className="mt-4 text-center d-none d-md-block">Sign In To</h4>
          <h4 className="text-center d-none d-md-block">Continue Access</h4>
          <div className="d-md-none mt-5 m-3">
            <h3>
              <b>Login via Microsoft using your SVAM account</b>
            </h3>
            <button className="login-btn mt-4" onClick={handleMicrosoftSubmit}>
              <object
                type="image/svg+xml"
                data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg"
                className="x-icon"
                aria-label="Microsoft Logo"
              ></object>{" "}
              Login with Microsoft
            </button>
          </div>
        </div>
      </div>
      <div className="d-none d-md-block col-md-6 d-md-flex align-items-center justify-content-center">
        <div className="">
          <h3>
            <b>Login via Microsoft using your SVAM account</b>
          </h3>
          <button className="login-btn mt-5" onClick={handleMicrosoftSubmit}>
            <object
              type="image/svg+xml"
              data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg"
              className="x-icon"
              aria-label="Microsoft Logo"
            ></object>{" "}
            Login with Microsoft
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
