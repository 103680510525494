import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Home from "./routes/Home/Home";
import Registration from "./routes/Registration/Registration";
import Quiz from "./routes/Quiz/Quiz";
import Recordings from "./routes/Recordings/Recordings";
import Login from "./routes/Login/Login";
import Reporting from "./routes/Reporting/Reporting";
import "./App.css";
import { AuthContextProvider } from "./Context/AuthContext";

import ProtectedRoute from "./components/ProtectedRoute";
import Account from "./routes/Account/Account";
import Main from "./routes/Main";
import { ColorRing } from "react-loader-spinner";
import Policies from "./routes/Policies/Policies";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  if (isLoading) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col d-flex align-items-center justify-content-center vh-100">
            <div className="Loader">
              <ColorRing
                height="150"
                width="150"
                colors={["#1BACCF", "#51298", "#65f7f2", "#ebfbfd", "#0fc9df"]}
              />
              <h1 className="loading-text">
                <b>Loading...</b>
              </h1>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Main />
              </ProtectedRoute>
            }
          >
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="registration" element={<Registration />} />
            <Route path="recordings" element={<Recordings />} />
            <Route path="reporting" element={<Reporting />} />
            <Route path="account" element={<Account />} />
            <Route path="Quiz" element={<Quiz />} />
            <Route path="policies" element={<Policies />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthContextProvider>
  );
}

export default App;
