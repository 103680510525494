import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../components/Navbar/Navbar'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'

function Main() {

    return (
        <div>

            <div className='row'>
                <div className='col-md-2 d-none d-md-block'>
                    <Navbar />
                </div>
                <div className="col-12 col-md-10">
                    <Header />
                    <Outlet />
                    <Footer />
                </div>
            </div>


        </div>
    )
}

export default Main