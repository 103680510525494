// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, collection } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBQHZsdeb0Y5NEzBZcNyFkJMixw30LEj04",
  authDomain: "cyberwebsite-98b89.firebaseapp.com",
  projectId: "cyberwebsite-98b89",
  storageBucket: "cyberwebsite-98b89.appspot.com",
  messagingSenderId: "1074335910393",
  appId: "1:1074335910393:web:95cc17166eba8fa1b4d528"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app)
const regRef = collection(db, "registration");

export {db, regRef};
export const auth = getAuth(app);
export default app

