// Footer.js
import React from 'react';
import './Footer.css';
function Footer() {
  return (
    <footer className='text-center mt-4'>
      <p>Copyright 2023 SVAM Cybersecurity Training Platform</p>
    </footer>
  );
}

export default Footer;
