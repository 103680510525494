import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  signInWithPopup,
  OAuthProvider,
} from "firebase/auth";
import { auth } from "../firebase";
import { db } from "../../src/firebase";

import { doc, setDoc } from "firebase/firestore";
const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});

  const createUser = async (email, password) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const user = res.user;
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        authProvider: "local",
        email,
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const microsoftLogin = async () => {
    try {
      const provider = new OAuthProvider("microsoft.com");
      provider.setCustomParameters({
        // Force re-consent.
        prompt: "consent",

        // Optional "tenant" parameter in case you are using an Azure AD tenant.
        // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
        // or "common" for tenant-independent tokens.
        // The default value is "common".
        tenant: "b2183828-8e6c-4cb5-b7b7-7211ac8de809",
      });
      /*  provider.addScope("mail.read");
    provider.addScope("calendars.read"); */
      const microsoftRes = await signInWithPopup(auth, provider)
        .then((result) => {
          // User is signed in.
          // IdP data available in result.additionalUserInfo.profile.

          // Get the OAuth access token and ID Token
          const credential = OAuthProvider.credentialFromResult(result);
          const accessToken = credential.accessToken;
          const idToken = credential.idToken;
          const msUser = result.user;
          return msUser;
        })
        .catch((error) => {
          // Handle error.
        });
      await setDoc(doc(db, "users", microsoftRes.uid), {
        uid: microsoftRes.uid,
        displayName: microsoftRes.displayName,
        email: microsoftRes.email,
      });
    } catch (err) {
      console.error(err);
    }
  };
  //redirect url in case we want to use redirect instead of popup window
  // https://cyberwebsite-98b89.firebaseapp.com/__/auth/handler

  //   const createUser = (email, password) => {
  //     return createUserWithEmailAndPassword(auth, email, password);
  //   };

  const logout = () => {
    return signOut(auth);
  };
  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      //console.log(currentUser);
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider
      value={{ user, createUser, signIn, logout, microsoftLogin }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
